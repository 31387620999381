<template>
<div>
  <div class="container">
    <div v-if="allServices">
      <div v-if="allServices.length > 0">
        <section class="section" v-if="services.length > 0">
          <h3 class="is-size-3 has-font-alternate has-margin-bottom">Active Services</h3>
          <el-table :data="serviceTableData" :default-sort = "{prop: 'date', order: 'descending'}">
            <el-table-column prop="service" label="Service" sortable></el-table-column>
            <el-table-column prop="summary" label="Summary">
                <template slot-scope="scope">
                  <p class="wrap-word"> {{ scope.row.summary }} </p>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="Date Created" :formatter="formatDate" sortable></el-table-column>
            <el-table-column width="200">
              <template slot-scope="scope">
                <div class="columns is-mobile">
                  <div class="column">
                    <router-link :to="`/service/${scope.row.id}`">
                        <el-button type="text">View</el-button>
                    </router-link>
                  </div>
                  <div class="column">
                    <router-link :to="`/edit-service/${scope.row.id}`">
                        <el-button type="text">Edit</el-button>
                    </router-link>
                  </div>
                  <div class="column">
                    <el-button
                      @click="activeServiceId = scope.row.id; archiveConfirmationVisible = true"
                      type="text">Archive
                    </el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <section class="section" v-if="archivedServices.length > 0">
          <h3 class="is-size-3 has-font-alternate has-margin-bottom">Archived Services</h3>
          <el-table :data="archivedServiceTableData" :default-sort = "{prop: 'date', order: 'descending'}">
            <el-table-column prop="service" label="Service" sortable></el-table-column>
            <el-table-column prop="description" label="Description">
                <template slot-scope="scope">
                  <p class="wrap-word"> {{ scope.row.description }} </p>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="Date Created" :formatter="formatDate" sortable></el-table-column>
            <el-table-column width="140">
              <template slot-scope="scope">
                <div class="columns is-mobile">
                  <div class="column">
                    <router-link :to="`/edit-service/${scope.row.id}`">
                        <el-button type="text">Edit</el-button>
                    </router-link>
                  </div>
                  <div class="column">
                    <el-button
                      @click="activeServiceId = scope.row.id; restoreConfirmationVisible = true"
                      type="text"
                    >Restore</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </div>
      <div v-else>Looks like you don't have any services yet</div>
    </div>
    <div v-else >
      Loading...
    </div>
  </div>
  <el-dialog
    title="Just making sure..."
    :visible.sync="archiveConfirmationVisible"
     width="30%"
    >
  <span>Are you sure you want to archive this service?</span>
  <span slot="footer" class="dialog-footer">
    <el-button type="text" @click="archiveConfirmationVisible = false">Cancel</el-button>
    <el-button type="primary" @click="archiveService(activeServiceId)">Archive</el-button>
  </span>
</el-dialog>
  <el-dialog
    title="Just making sure..."
    :visible.sync="restoreConfirmationVisible"
     width="30%"
    >
  <span>Are you sure you want to restore this service?</span>
  <span slot="footer" class="dialog-footer">
    <el-button type="text" @click="restoreConfirmationVisible = false">Cancel</el-button>
    <el-button type="primary" @click="restoreService(activeServiceId)">Restore</el-button>
  </span>
</el-dialog>
</div>

</template>

<script>
import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import { listServices } from '@/graphql/queries';
import { updateService } from '@/graphql/mutations';
import { timeFormat } from 'd3';

const serviceDateTimeFormat = timeFormat('%b %d at %-I:%M %p')
const serviceTableMap = service => ({
  id: service.id,
  service: service.title,
  description: service.description,
  summary: service.summary,
  date: new Date(service.createdAt),
})

export default {
  name: 'Services',
  data() {
    return {
      services: null,
      allServices: null,
      archivedServices: null,
      activeServiceId: null,
      archiveConfirmationVisible: false,
      restoreConfirmationVisible: false,
    }
  },
  async created() {
    this.fetchServices()
  },
  computed: {
    listServicesQuery() {
      return graphqlOperation(listServices)
    },
  },
  methods: {
    formatDate(row, col, cellValue) {
      return serviceDateTimeFormat(cellValue)
    },
    async fetchServices() {
      const res = await API.graphql(graphqlOperation(
        listServices,
        {
          limit: 1000,
          filter: {
            owner: {
              eq: this.$store.state.user.username
            }
          }
        }
      ))
      this.allServices = res.data.listServices.items
      this.services = this.allServices.filter(service => !service.archived)
      this.archivedServices = this.allServices.filter(service => service.archived)
      this.serviceTableData = this.services.map(serviceTableMap)
      this.archivedServiceTableData = this.archivedServices.map(serviceTableMap)
    },
    async archiveService(id) {
      await API.graphql(graphqlOperation(
        updateService,
        {
          input: {
            id,
            archived: true,
          }
        },
      ))
      this.activeService = null
      this.archiveConfirmationVisible = false
      this.fetchServices()
    },
    async restoreService(id) {
      await API.graphql(graphqlOperation(
        updateService,
        {
          input: {
            id,
            archived: false,
          }
        },
      ))
      this.activeService = null
      this.restoreConfirmationVisible = false
      this.fetchServices()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap-word {
  word-break: break-word;
}
</style>

