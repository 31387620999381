/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getService = `query GetService($id: ID!) {
  getService(id: $id) {
    id
    title
    customLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    previews {
      type
      value
      description
    }
    amount
    createdAt
    summary
    description
    input {
      type
      key
      label
      placeholder
      required
      description
      defaultValue
      accept
      options {
        value
        label
      }
      selectMultiple
      selectNone
      selectCreate
    }
    archived
    apiType
    inputEndpoint {
      url
      action
    }
    statusEndpoint {
      url
      action
    }
    outputEndpoint {
      url
      action
    }
    sampleResponse {
      type
      key
      label
      description
      exampleValue
    }
    owner
    draft
    plans {
      items {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      nextToken
    }
    stripeId
    seoTitle
    seoDescription
    seoImage
  }
}
`;
export const listServices = `query ListServices(
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    nextToken
  }
}
`;
export const getRequisition = `query GetRequisition($id: ID!) {
  getRequisition(id: $id) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    amount
    input {
      key
      value
      label
      type
    }
    createdAt
    status {
      value
      date
      description
    }
    output {
      key
      value
      label
      type
    }
    outputNote
    canceled
    refunded
    archived
    test
    owner
    vendor
    api
    chargeId
    receiptUrl
    vendorMetadata {
      key
      value
    }
    paymentType
    subscription {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      createdAt
      vendor
      onCreateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onUpdateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onDeleteService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onCreateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onUpdateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onDeleteRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onCreateCustomLink {
        id
        owner
      }
      onUpdateCustomLink {
        id
        owner
      }
      onDeleteCustomLink {
        id
        owner
      }
      onCreatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onUpdatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onDeletePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
    }
  }
}
`;
export const listRequisitions = `query ListRequisitions(
  $id: ID
  $filter: ModelRequisitionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRequisitions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    nextToken
  }
}
`;
export const getCustomLink = `query GetCustomLink($id: ID!) {
  getCustomLink(id: $id) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    owner
  }
}
`;
export const listCustomLinks = `query ListCustomLinks(
  $filter: ModelCustomLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    nextToken
  }
}
`;
export const getSubscription = `query GetSubscription($id: ID!) {
  getSubscription(id: $id) {
    id
    stripeId
    owner
    plan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    createdAt
    vendor
    onCreateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onUpdateService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onDeleteService {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    onCreateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onUpdateRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onDeleteRequisition {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    onCreateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onUpdateCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onDeleteCustomLink {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      owner
    }
    onCreatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onUpdatePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    onDeletePlan {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
  }
}
`;
export const listSubscriptions = `query ListSubscriptions(
  $filter: ModelSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stripeId
      owner
      plan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      createdAt
      vendor
      onCreateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onUpdateService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onDeleteService {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      onCreateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onUpdateRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onDeleteRequisition {
        id
        amount
        createdAt
        outputNote
        canceled
        refunded
        archived
        test
        owner
        vendor
        api
        chargeId
        receiptUrl
        paymentType
      }
      onCreateCustomLink {
        id
        owner
      }
      onUpdateCustomLink {
        id
        owner
      }
      onDeleteCustomLink {
        id
        owner
      }
      onCreatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onUpdatePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
      onDeletePlan {
        id
        stripeId
        name
        allowance
        description
        owner
      }
    }
    nextToken
  }
}
`;
export const getPlan = `query GetPlan($id: ID!) {
  getPlan(id: $id) {
    id
    service {
      id
      title
      customLink {
        id
        owner
      }
      previews {
        type
        value
        description
      }
      amount
      createdAt
      summary
      description
      input {
        type
        key
        label
        placeholder
        required
        description
        defaultValue
        accept
        selectMultiple
        selectNone
        selectCreate
      }
      archived
      apiType
      inputEndpoint {
        url
        action
      }
      statusEndpoint {
        url
        action
      }
      outputEndpoint {
        url
        action
      }
      sampleResponse {
        type
        key
        label
        description
        exampleValue
      }
      owner
      draft
      plans {
        nextToken
      }
      stripeId
      seoTitle
      seoDescription
      seoImage
    }
    stripeId
    name
    allowance
    description
    owner
  }
}
`;
export const listPlans = `query ListPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      stripeId
      name
      allowance
      description
      owner
    }
    nextToken
  }
}
`;
export const reqsByOwner = `query ReqsByOwner(
  $owner: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRequisitionFilterInput
  $limit: Int
  $nextToken: String
) {
  reqsByOwner(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    nextToken
  }
}
`;
export const reqsByVendor = `query ReqsByVendor(
  $vendor: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRequisitionFilterInput
  $limit: Int
  $nextToken: String
) {
  reqsByVendor(
    vendor: $vendor
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      service {
        id
        title
        amount
        createdAt
        summary
        description
        archived
        apiType
        owner
        draft
        stripeId
        seoTitle
        seoDescription
        seoImage
      }
      amount
      input {
        key
        value
        label
        type
      }
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      chargeId
      receiptUrl
      vendorMetadata {
        key
        value
      }
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
        vendor
      }
    }
    nextToken
  }
}
`;
